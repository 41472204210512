import { getApiClient, setResponseInterceptor } from "./axiosBase";

/*
Azure Static Web App task doesn't currently support passing environment variables
from the pipeline. So we're using token replacement to control the URL from the pipeline
during deployment on Azure.
*/
const apiBaseUrl = process.env.VUE_APP_HEADER_API_BASE_URL ?? "#{VUE_APP_HEADER_API_BASE_URL}#";
const apiSubscriptionKey = process.env.VUE_APP_HEADER_API_SUBSCRIPTION_KEY ?? "#{VUE_APP_HEADER_API_SUBSCRIPTION_KEY}#";

const headerApiClient = getApiClient(apiBaseUrl, "Ocp-Apim-Subscription-Key", apiSubscriptionKey);
setResponseInterceptor(headerApiClient);

export async function getApplicationList() {
    const res = await headerApiClient.get("/CommonHeader/applications");
    return res.data;
}

export async function updateApplication(id, app, groups) {
    await headerApiClient.put(`/CommonHeader/applications/${id}`, {Application: app, AccessGroups: groups});
}

export async function addApplication(app, groups) {
    const res = await headerApiClient.post("/CommonHeader/applications", {Application: app, AccessGroups: groups});
    return res.data;
}

export async function removeApplication(id) {
    await headerApiClient.delete(`/CommonHeader/applications/${id}`);
}

export async function getAccessGroupsForApplication(id) {
    const res = await headerApiClient.get(`/CommonHeader/applications/${id}/access-groups`);
    return res.data;
}