import { useToast, POSITION, TYPE } from 'vue-toastification';
import Toastification from "@/components/Toast/Toastification.vue";

export default function () {
    const toast = useToast();

    const displayToast = (severity, content, life) => {
        if (!life) {
            life = 3000;
        }
        toast(content, {
            position: POSITION.TOP_RIGHT,
            timeout: life,
            type: severity
        });
    };

    const displayInfoToast = (summary, detail, life) => {
        displayToast(TYPE.INFO, getContent(summary, detail), life);
    };

    const displaySuccessToast = (summary, detail, life) => {
        displayToast(TYPE.SUCCESS, getContent(summary, detail), life);
    };

    const displayErrorToast = (summary, detail, life) => {
        displayToast(TYPE.ERROR, getContent(summary, detail), life);
    };

    const getContent = (summary, detail) => {
        return {
            component: Toastification,
            props: {
                toastTitle: summary,
                toastMessage: detail
            }
        }
    };

    return {
        displayInfoToast,
        displaySuccessToast,
        displayErrorToast
    };
}
