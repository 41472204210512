import { OktaAuth } from '@okta/okta-auth-js';

const oktaIssuer = process.env.VUE_APP_OKTA_ISSUER ?? "#{VUE_APP_OKTA_ISSUER}#";
const clientId = process.env.VUE_APP_OKTA_CLIENT_ID ?? "#{VUE_APP_OKTA_CLIENT_ID}#";

const oktaAuthClient = new OktaAuth({
    issuer: oktaIssuer,
    clientId: clientId,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email', 'groups']
});

oktaAuthClient.tokenManager.on('error', (error) => {
    console.log(error);
    oktaAuthClient.signInWithRedirect();
});

const getCurrentUserGroups = async () => {
    let userGroups = [];
    if (oktaAuthClient.token) {
        const user = await getCurrentUser();
        userGroups = user.groups ?? [];
    }
    return userGroups;
};

const hasAdminAccess = async (groups) => {
    const adminGroup = process.env.VUE_APP_ADMIN_GROUP_NAME ?? "#{VUE_APP_ADMIN_GROUP_NAME}#";

    let userGroups = []
    if (!groups || !(groups.length > 0)) {
        userGroups = await getCurrentUserGroups();
    }
    else {
        userGroups = groups;
    }

    const hasAdminAccess = userGroups.some(group => group.toLowerCase() === adminGroup.toLowerCase());

    return hasAdminAccess;
};

const getCurrentUser = async () => {
    const user = await oktaAuthClient.getUser();
    const currentUser = {
        user: user,
        hasAdminAccess: await hasAdminAccess(user.groups)
    }

    return currentUser;
};

export { oktaAuthClient, getCurrentUser };