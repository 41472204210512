<template>
    <div>{{ a }}</div>
</template>
<script>
export default {
    name: "my-account",
    components: {
    },
    setup() {
        const a = "My Account";
        return {
            a
        };
    },
};
</script>