<template>
  <div>
    <ApplicationHeader />
    <div class="app-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import ApplicationHeader from "@/components/header/ApplicationHeader.vue";

export default {
  name: "App",
  components: {
    ApplicationHeader,
  },
};
</script>

<style>
body{
  margin: 0
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.app-container {
  padding-top: 10px;
  margin: 2%;
  min-height: 45vw;
  background-color: white;
}
</style>
