<template>
  <Loading :is-loading="isLoading" loading-background="#FFFFFF" :loading-opacity="1" />
  <Header applicationTitle="OneData Portal" iconPath="assets/1DLogoSm.png">
    <template v-slot:controls>
      <router-link to="/">
        <div id="btn-home">
          <BaseButton icon="pi pi-home" label="Home" class="p-button-text p-button-secondary p-mr-2" />
        </div>
      </router-link>
      <div v-if="user.hasAdminAccess">
        <router-link to="/admin">
          <BaseButton icon="pi pi-briefcase" label="Admin" class="p-button-text p-button-secondary p-mr-2" />
        </router-link>
      </div>
      <div v-if="isAuthenticated" id="btn-user-info">
        <SplitButton :label="user.user?.given_name" icon="pi pi-user" :model="userDropdown"
          class="p-button-text p-button-secondary p-mr-2"></SplitButton>
      </div>
      <div v-else>
        <BaseButton @click="signIn" icon="pi pi-sign-in" label="Login"
          class="p-button-text p-button-secondary p-mr-2" />
      </div>
    </template>
  </Header>
</template>
<script>
import { oktaAuthClient, getCurrentUser } from "@/okta/oktaHandler";
import SplitButton from "primevue/splitbutton";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "application-header",
  components: {
    SplitButton
  },
  setup() {
    const router = useRouter();
    const user = ref({});
    const isAuthenticated = ref(false);
    const isMyAccountVisible = (process.env.VUE_APP_IS_MY_ACCOUNT_VISIBLE ?? "#{VUE_APP_IS_MY_ACCOUNT_VISIBLE}#").toLowerCase() === "true";
    const userDropdown = ref([
      {
        label: "My Account",
        command: () => {
          router.push({
            name: 'MyAccount'
          });
        },
        visible: isMyAccountVisible
      },
      {
        label: "Logout",
        command: () => {
          if (isAuthenticated.value) {
            oktaAuthClient.signOut();
          }
        },
      },
    ]);

    const isLoading = ref(true);
    setTimeout(async () => {
      try {
        isAuthenticated.value = await oktaAuthClient.isAuthenticated();
        if (isAuthenticated.value) {
          user.value = await getCurrentUser();
        }
      } finally {
        isLoading.value = false;
      }
    }, 1000);

    const signIn = () => {
      oktaAuthClient.signInWithRedirect();
    };

    return { user, isAuthenticated, userDropdown, isLoading, signIn };
  }
};
</script>
