import axios from "axios";
import toastHandler from "@/helpers/toastHandler";

const { displayErrorToast } = toastHandler();

const validateApiUrl = (url) => {
    let isUrlValid = true;
    try {
        new URL(url);
    }
    catch (error) {
        isUrlValid = false;
        console.log(`Invalid Header API URL: ${url}`);
        console.log(error);
    }

    return isUrlValid;
};

const getApiClient = (url, headerKey, headerValue) => {
    if (!validateApiUrl(url)) {
        displayErrorToast("Invalid API URL", `The provided API URL is invalid: ${url}`);
    }
    return axios.create({
        baseURL: url,
        headers: {
            [headerKey]: headerValue
        }
    });
};

const setResponseInterceptor = (apiClient) => {
    apiClient.interceptors.response.use(response => {
        return response;
    }, error => {
        const title = "API Operation Failed"
        const message = error.response?.data?.message ? error.response.data.message :
            "Something went wrong. A detailed error log has been written on the console.";

        console.log(error.response.data);
        displayErrorToast(title, message, 5000);

        return Promise.reject(error.response);
    });
};

export { validateApiUrl, getApiClient, setResponseInterceptor };
