<template>
    <div class="no-access">
        <h1>Access Forbidden</h1>
        <div>You may not have access to this page</div>
        <router-link :to="homeLink">Back to Home</router-link>
    </div>
</template>
  
<script>
export default {
    name: "no-access",
    props: {
        homeLink: {
            type: String,
            default: () => "/",
        },
    },
};
</script>
  
<style scoped>
.no-access {
    text-align: center;
    font-size: 20px;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}
</style>