<template>
    <ConfirmPopup />
    <Loading :isLoading="loading" />
    <div class="grid margin-top-1">
        <BaseButton class="col-3 col-offset-2" name="Create New Application" icon="pi pi-plus"
            @click="createApplication" />
        <Dropdown class="col-3 col-offset-2" v-model="selectedApp" :options="appList" optionLabel="applicationName"
            placeholder="... or Select an Application to Modify" @change="selectApplication" />
    </div>
    <hr class="margin-top-1" />
    <div v-if="selectedApp" class="grid">
        <div class="col-5 col-offset-1 margin-top-2">
            <span class="p-float-label">
                <InputText class="width-100" v-model="selectedApp.applicationName" />
                <label for="inputtext">Application Name</label>
            </span>
        </div>
        <div class="col-5 margin-top-2">
            <span class="p-float-label">
                <InputText class="width-100" v-model="selectedApp.applicationId" />
                <label for="inputtext">Application ID</label>
            </span>
        </div>
        <div class="col-5 col-offset-1 margin-top-2">
            <span class="p-float-label">
                <InputText class="width-100" v-model="selectedApp.applicationIcon" />
                <label for="inputtext">Application Icon</label>
            </span>
        </div>
        <div class="col-5 margin-top-2">
            <span class="p-float-label">
                <InputText class="width-100" v-model="selectedApp.applicationUrl" />
                <label for="inputtext">Application URL</label>
            </span>
        </div>
        <DataTable class="col-5 col-offset-1 margin-top-2" :value="accessGroups" stripedRows editMode="cell"
            @cellEditComplete="cellEditComplete">
            <Column header="Access Groups" field="accessGroup">
                <template #body="{ data, field }">
                    <div v-if="data[field]" class="width-100 text">
                        {{ data[field] }}
                    </div>
                    <div v-else class="width-100 text-align-center pointer">
                        <i class="pi pi-plus" />
                    </div>
                </template>
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus class="width-100" />
                </template>
            </Column>
        </DataTable>
        <div class="col-2 col-offset-1 margin-top-2">
            <BaseButton class="width-100 green" name="Save App" icon="pi pi-save" @click="saveApplication" />
            <BaseButton v-if="selectedApp.id" class="width-100 margin-top-1 red" name="Remove App" icon="pi pi-trash" @click="removeApplication($event)" />
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import * as headerApiClient from "@/api/headerApiClient";
import toastHandler from "@/helpers/toastHandler";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useConfirm } from "primevue/useconfirm";
import ConfirmPopup from 'primevue/confirmpopup';

export default {
    name: "admin-portal",
    components: {
        Dropdown,
        InputText,
        DataTable,
        Column,
        ConfirmPopup
    },
    setup() {
        const loading = ref(false);
        const appList = ref([]);
        const accessGroups = ref([{}]);
        const selectedApp = ref();
        const { displayErrorToast, displaySuccessToast } = toastHandler();
        const confirm = useConfirm();

        onMounted(async () => {
            loading.value = true;
            try {
                await loadAppList();
            }
            finally {
                loading.value = false;
            }
        });

        const loadAppList = async () => {
            //Call CommonHeaderApi to retrieve the list of applications
            var res = await headerApiClient.getApplicationList();
            if (res) {
                appList.value = res;
            } else {
                const title = "No Applications Found";
                const message =
                    "Something went wrong. The list of available applications is empty.";
                displayErrorToast(title, message, 5000);
            }
        };

        const selectApplication = async () => {
            loading.value = true;
            try {
                accessGroups.value = await headerApiClient.getAccessGroupsForApplication(selectedApp.value.id);
                addEmptyRow();
            }
            finally {
                loading.value = false;
            }
        }

        const createApplication = () => {
            selectedApp.value = {};
            accessGroups.value = [];
            addEmptyRow();
        }

        const saveApplication = async () => {
            if (!selectedApp.value.applicationName || !selectedApp.value.applicationId || !selectedApp.value.applicationIcon || !selectedApp.value.applicationUrl) {
                displayErrorToast("Missing Fields", "Please fill out the Application Name, ID, Icon, and URL", 5000);
                return;
            }

            loading.value = true;
            try {
                var appId = selectedApp.value.id;
                removeEmptyRows();

                if(appId) {
                    accessGroups.value.forEach(g => g.applicationId = appId);
                    await headerApiClient.updateApplication(appId, selectedApp.value, accessGroups.value);
                }
                else {
                    var id = await headerApiClient.addApplication(selectedApp.value, accessGroups.value);
                    await loadAppList();
                    selectedApp.value = appList.value.filter(a => a.id === id)[0];
                    console.log(selectedApp.value);
                }

                displaySuccessToast("Application Saved", "Your changes have been saved!", 5000);
            }
            finally {
                loading.value = false;
            }
        }

        const removeApplication = (event) => {
            confirm.require({
                target: event.target,
                message: "Remove this application?",
                icon: "pi pi-trash",
                accept: async () => {
                    loading.value = true;
                    try {
                        await headerApiClient.removeApplication(selectedApp.value.id);
                        await loadAppList();
                        createApplication();
                        displaySuccessToast("Application Removed", "The application has been removed!", 5000);
                    }
                    finally {
                        loading.value = false;
                    }
                }
            });
        }

        const cellEditComplete = (event) => {
            var { data, newValue, field } = event;

            if (!data[field] && newValue) {
                addEmptyRow();
            }

            data[field] = newValue;

            if (!newValue) {
                removeEmptyRows();
                addEmptyRow();
            }
        }

        const removeEmptyRows = () => {
            accessGroups.value = accessGroups.value.filter(g => g.accessGroup);
        }

        const addEmptyRow = () => {
            accessGroups.value.push({ accessGroup: '' });
        }

        return {
            loading,
            appList,
            accessGroups,
            selectedApp,
            selectApplication,
            createApplication,
            saveApplication,
            removeApplication,
            cellEditComplete
        };
    },
};
</script>

<style scoped>
.margin-top-1 {
    margin-top: 1em;
}

.margin-top-2 {
    margin-top: 2em;
}

.width-100 {
    width: 100%;
}

.text-align-center {
    text-align: center;
}

.green {
    background-color: green;
    border-color: green;
}

.red {
    background-color: rgb(161, 0, 0);
    border-color: rgb(161, 0, 0);
}
.pointer {
    cursor: pointer;
}
.text {
    cursor: text;
}
</style>