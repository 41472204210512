import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toastification from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueBaseComponentLibrary from 'vue-base-component-library';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import OktaVue from '@okta/okta-vue';
import { oktaAuthClient } from './okta/oktaHandler';
import ConfirmationService from 'primevue/confirmationservice';

createApp(App)
    .use(VueBaseComponentLibrary)
    .use(PrimeVue)
    .use(router)
    .use(Toastification)
    .use(ConfirmationService)
    .use(OktaVue, {
        oktaAuth: oktaAuthClient,
        onAuthRequired: (oktaAuthClient) => {
            oktaAuthClient.signInWithRedirect();
        }
    })
    .mount('#app');
