<template>
  <a :href="applicationUrl" target="_blank">
    <Card style="width: 20rem; margin: 1em; padding: 1em">
      <template #header>
        <em :class="applicationIcon" style="font-size: 4rem" />
      </template>
      <template #title> {{ applicationName }} </template>
    </Card>
  </a>
</template>

<script>
import Card from "primevue/card";

export default {
  name: "application-card",
  components: {
    Card,
  },
  props: {
    applicationIcon: {
      type: String,
      default: "",
    },
    applicationUrl: {
      type: String,
      default: "",
    },
    applicationName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
a {
  display: inline-block;
}

a:link {
  text-decoration: none;
}

:deep(.p-card-content) {
  display: none;
}
</style>