import { createRouter, createWebHistory } from 'vue-router';
import { LoginCallback, navigationGuard } from '@okta/okta-vue';
import { getCurrentUser } from "@/okta/oktaHandler";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/account',
        name: 'MyAccount',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/MyAccount.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/no-access',
        name: 'NoAccess',
        component: () => import(/* webpackChunkName: "NoAccess" */ '@/views/NoAccess.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/admin',
        name: 'AdminPortal',
        component: () => import(/* webpackChunkName: "AdminPortal" */ '@/views/AdminPortal.vue'),
        beforeEnter: async (to, from, next) => {
            const user = await getCurrentUser();
            if (user?.hasAdminAccess) {
                next();
            }
            else {
                next('NoAccess');
            }
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login/callback',
        component: LoginCallback
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/Error.vue'),
        props: { homeLink: "/" },
        meta: {
            requiresAuth: false
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach(async (to) => await navigationGuard(to));

export default router;
