<template>
  <Loading :isLoading="loadingAppList" />
  <div class="home-container">
    <div style="text-align: center">
      <h1>OneData Applications</h1>
      <BaseSearchBar placeHolderText="Find Application" @search-typeahead="filterApplications" />
    </div>
    <div>
      <ApplicationCard class="app-card" style="text-align: center" v-for="app in filteredAppList"
        :key="app.applicationName" :applicationIcon="app.applicationIcon" :applicationName="app.applicationName"
        :applicationUrl="app.applicationUrl" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import ApplicationCard from "@/components/display/ApplicationCard.vue";
import * as headerApiClient from "@/api/headerApiClient";
import toastHandler from "@/helpers/toastHandler";

export default {
  name: "home-view",
  components: {
    ApplicationCard,
  },
  setup() {
    const appList = ref([]);
    const filteredAppList = ref([]);
    const loadingAppList = ref(true);
    const { displayErrorToast } = toastHandler();

    onMounted(async () => {
      try {
        loadingAppList.value = true;
        await loadAppList();
      } finally {
        loadingAppList.value = false;
      }
    });

    const loadAppList = async () => {
      //Call CommonHeaderApi to retrieve the list of applications
      var res = await headerApiClient.getApplicationList();
      if (res) {
        appList.value = res;
        filteredAppList.value = appList.value;
      } else {
        const title = "No Applications Found";
        const message =
          "Something went wrong. The list of available applications is empty.";
        displayErrorToast(title, message, 5000);
      }
    };

    const filterApplications = (event) => {
      filteredAppList.value = appList.value.filter((app) =>
        app.applicationName.toLowerCase().includes(event.toLowerCase())
      );
    };

    return {
      appList,
      filteredAppList,
      filterApplications,
      loadAppList,
      loadingAppList,
    };
  },
};
</script>
<style scoped>
.home-container {
  margin: 1%;
}

.home-container :deep(.p-card):hover {
  cursor: pointer;
  box-shadow: 0 0 0 0.2rem #005F9E;
}
</style>